import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {HeroComponent} from './hero/hero.component';
import {StoryComponent} from "./story/story.component";
import {ContactComponent} from "./contact/contact.component";
import {AboutComponent} from "./about/about.component";
import {ReaderComponent} from "./reader/reader.component";

const routes: Routes = [
    {path: '', component: HeroComponent, data: {state: 'hero'}},
    {path: 'story', component: StoryComponent, data: {state: 'story'}},
    {path: 'contact', component: ContactComponent, data: {state: 'contact'}},
    {path: 'about', component: AboutComponent, data: {state: 'about'}},
    {path: 'read', component: ReaderComponent, data: {state: 'read'}},
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
