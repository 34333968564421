import { Component, OnInit } from '@angular/core';
import {Router} from '@angular/router';


@Component({
  selector: 'app-hero',
  templateUrl: './hero.component.html',
  styleUrls: ['./hero.component.scss']
})
export class HeroComponent implements OnInit {

  hasScrolled;
  constructor(private router: Router) { }

  ngOnInit() {
    this.hasScrolled = false;
    document.getElementById('hero').addEventListener('wheel', (e) => {
      this.scrollEvent(e);
    });
  }

  scrollEvent(e) {
    console.log("scroll hero");
    if (!this.hasScrolled) {
      if (e.deltaY > 0) {
        this.router.navigate(['/read']);
      }else{
        this.router.navigate(['/']);
      }
      this.hasScrolled = true;
    }
  }


}
