import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';

@Component({
  selector: 'app-reader',
  templateUrl: './reader.component.html',
  styleUrls: ['./reader.component.scss']
})
export class ReaderComponent implements OnInit {
  index = 1;
  max;
  keyEventStorage;
  numbers = [];
  hasScrolled = false;
  audio: any;

  mp4Files = [1,2,3,4,5,6,9,10,11,14,15,16,18,25,26,28,29,34,35,42,43,45,46,47,49,50,58,59,64,66,67,68,72,73,78,79,80,81,84,86,91,92,94,99,105,113,118,130,132,133,135,137,
    145,147,148,149,150,151,152,153,154,155,156,157,158,159,160];
  loops = [14,16,25,42,46,66,67,72,73,80,133,137,145,147,148,151,152,153,154,155,156,157,158,159,160];
  generique = 160;

  constructor(private router: Router) {
    this.audio = new Audio();
    this.audio.src = "../../../assets/musique_generique.mp3";
    this.audio.load();

  }

  ngOnInit() {
    this.max = 166;
    for(var i = 1; i <= this.max; i++){
      this.numbers.push(i);
    }

    // this.keyEventStorage = this.keyUpHandler.bind(this);

    // document.addEventListener('keyup', event=>this.keyUpHandler(event));
    // document.addEventListener('wheel', event=>this.scrollEvent(event));

      document.addEventListener('keyup', (e) => {
          this.keyUpHandler(e)
      });
      document.addEventListener('wheel', (e) => {
          this.scrollEvent(e)
      });
    this.preloadImages();

  }

  preloadImages(){
    var preloadImages = [];
    var preloadMP4s = [];
    var content = [];

    for (var i = 1; i <= this.max; i++){
      if(this.mp4Files.indexOf(i) == -1){
        preloadImages.push(i+'.jpg');
        content[i] = new Image();
        content[i].src = '../../../assets/img/BD/'+i+'.jpg';
      }else{
        preloadMP4s.push(i+'.mp4');
        content[i] = document.createElement("VIDEO");
        content[i].src = '../../../assets/img/BD/'+i+'.mp4';
      }
    }
  }

  next() {
    if (this.index < this.max) {
      console.log("next ("+this.index+'=>'+(this.index+1)+")");
      this.index++;
    }

    if(this.index === this.generique){
      this.playAudio();
    }
  }

  previous() {
    if (this.index > 1) {
      console.log("previous ("+this.index+'=>'+(this.index-1)+")");
      this.index--;
    }
    if(this.index < this.generique){
      this.audio.pause();
    }
  }

  goto(index) {
    this.index = index;
  }

  keyUpHandler(event: any) {
    console.log("key event (reader)");
    switch (event.key) {
      case 'ArrowLeft' : this.previous(); break;
      case 'ArrowRight' : this.next(); break;
    case 'ArrowUp' : this.router.navigate(['/']);break;

    }
  }

  isMP4(_index){
    return this.mp4Files.indexOf(_index) !== -1;
  }

  isLoop(_index){
    return this.loops.indexOf(_index) !== -1;
  }

  scrollEvent(e) {
    if (!this.hasScrolled) {
      if (e.deltaY < 0) {
        this.router.navigate(['/']);
      }
      this.hasScrolled = true;
    }
  }
  
  playAudio(){
    this.audio.currentTime = 0;
    this.audio.play();
  }
}
