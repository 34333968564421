import {trigger, animate, style, group, animateChild, query, stagger, transition} from '@angular/animations';

export const routerTransition = trigger('routerTransition', [
  transition('hero => read, story => read, about => read, contact => read', [
    /* order */
    /* 1 */
    query(':enter, :leave', style({ position: 'fixed', width: '100%' })
      , { optional: true }),
    /* 2 */
    group([  // block executes in parallel
      query(':enter', [
        style({ transform: 'translateY(100%)' }),
        animate('0.5s ease-in-out', style({ transform: 'translateY(0%)' }))
      ], { optional: true }),
      query(':leave', [
          style({ transform: 'translateY(0%)' }),
          animate('0.5s ease-in-out', style({ transform: 'translateY(-100%)' }))]
        , { optional: true }),
    ])
  ]),
  transition('read=>hero, read=>story, read=>about, read=>contact', [
    /* order */
    /* 1 */
    query(':enter, :leave', style({ position: 'fixed', width: '100%' })
      , { optional: true }),
    /* 2 */
    group([  // block executes in parallel
      query(':enter', [
        style({ transform: 'translateY(-100%)' }),
        animate('0.5s ease-in-out', style({ transform: 'translateY(0%)' }))
      ], { optional: true }),
      query(':leave', [
          style({ transform: 'translateY(0%)' }),
          animate('0.5s ease-in-out', style({ transform: 'translateY(100%)' }))]
        , { optional: true }),
    ])
  ]),


  transition('hero => story, hero =>about, hero => contact,' +
      'story => about, story => contact, about=>contact', [
    /* order */
    /* 1 */
    query(':enter, :leave', style({ position: 'fixed', width: '100%' })
        , { optional: true }),
    /* 2 */
    group([  // block executes in parallel
      query(':enter', [
        style({ transform: 'translateX(100%)' }),
        animate('0.5s ease-in-out', style({ transform: 'translateX(0%)' }))
      ], { optional: true }),
      query(':leave', [
            style({ transform: 'translateX(0%)' }),
            animate('0.5s ease-in-out', style({ transform: 'translateX(-100%)' }))]
          , { optional: true }),
    ])
  ]),

    transition('contact=>about, contact=>story, contact=>hero, about=>story, about=>hero, story=>hero', [
        /* order */
        /* 1 */
        query(':enter, :leave', style({ position: 'fixed', width: '100%' })
            , { optional: true }),
        /* 2 */
        group([  // block executes in parallel
            query(':enter', [
                style({ transform: 'translateX(-100%)' }),
                animate('0.5s ease-in-out', style({ transform: 'translateX(0%)' }))
            ], { optional: true }),
            query(':leave', [
                    style({ transform: 'translateX(0%)' }),
                    animate('0.5s ease-in-out', style({ transform: 'translateX(100%)' }))]
                , { optional: true }),
        ])
    ]),

]);
